<template>
  <el-dialog
    :title="payTitle"
    :close-on-click-modal="false"
    :visible.sync="visible"
    @close="closeDialog"
    class="table"
    width="560px"
  >
    <div class="qrcode-way">
      <div class="mpay-qrcode-container">
        <div ref="qrcodeContainer" class="qrcode-container"></div>
      </div>
      <div class="mpay-qrcode-tips">
        <p class="pay-info-container">
          <span>Bank:BCA</span>
        </p>
        <p class="pay-info-container">
          <span>Account number:5271307358</span>
        </p>
        <p class="pay-info-container">
          Recharge amount:RP<span class="need-to-pay">{{ nowprice }}</span>
        </p>
        <p class="pay-info-container">
          Balance:<span class="need-to-pay">{{ nowPreferential }}</span
          >B
        </p>
        <p class="pay-info-container">
          Expire:<span class="need-to-pay">{{ nowDays }}</span
          >day active
        </p>
        <div class="warning">
          <p>
            Tip: be sure to transfer the above amount to the designated account,
            and recharge the account after 5-15 minutes.
          </p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getPayZFBERCode } from "../../../api/pay";
import { paySuccess } from "../../../api/charge";
import QRCode from "qrcodejs2";
import { message } from "@/utils/resetMessage";
import countdown from "@/components/tempcount/countdown.vue";
export default {
  components: {
    countdown,
  },
  props: [
    "dialogVisible",
    "curPreferential",
    "currentPrice",
    "dialogPaying",
    "currentPayType",
    "currentCouponId",
    "currentDays",
  ],
  watch: {
    curPreferential(newValue) {
      if (!this.dialogPaying) {
        this.nowPreferential = newValue;
      }
    },
    currentPrice(newValue) {
      if (!this.dialogPaying) {
        this.nowprice = newValue;
      }
    },
    currentPayType(newValue) {
      if (!this.dialogPaying) {
        this.payType = newValue;
        if (this.payType == "Bank") {
          this.payName = "CekMutasi.Co.Id";
          this.payTitle = "CekMutasi.Co.Id";
        } else {
          this.payName = "Card";
          this.payTitle = "Card Charge";
        }
      }
    },
    currentCouponId(newValue) {
      if (!this.dialogPaying) {
        this.nowCouponId = newValue;
      }
    },
    currentDays(newValue) {
      if (!this.dialogPaying) {
        this.nowDays = newValue;
      }
    },
    dialogVisible: {
      handler(val) {
        this.visible = val;
        if (val && !this.dialogPaying) this.getERCodeDatasZFB();
      },
      deep: true,
    },
  },
  data() {
    return {
      visible: false,
      nowprice: this.currentPrice,
      nowPreferential: this.curPreferential,
      nowCouponId: this.currentCouponId,
      nowDays: this.currentDays,
      erCode: null,
      payType: "Bank",
      payName: "CekMutasi.Co.Id",
      payTitle: "CekMutasi.Co.Id",
      info: {
        isFlag: false,
        currentTime: "",
        startDate: "",
        endDate: "",
      },
      currentOrderId: "", //当前订单ID
      isPaying: false, //是否正在支付
      timer: "", // 定时任务
    };
  },
  methods: {
    //支付宝面对面充值
    getERCodeDatasZFB() {
      //获取二维码链接
      let dataForm = {
        order_type: this.payType,
        order_price: this.currentPrice,
        other_price: this.curPreferential,
        order_name: this.getRandomCode(10),
        order_days: this.currentDays,
        extension: "new",
      };

      getPayZFBERCode(dataForm)
        .then((result) => {
          //this.$emit("onPay", true); // 创建订单的状态
          this.currentOrderId = result.paySerial;
        })
        .catch((error) => {
          message.error({
            message: "Too Many Orders",
            duration: 4000,
            offset: 80,
          });
        });
    },

    getRandomCode(length) {
      if (length > 0) {
        var data = [
          "0",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "A",
          "B",
          "C",
          "D",
          "E",
          "F",
          "G",
          "H",
          "I",
          "J",
          "K",
          "L",
          "M",
          "N",
          "O",
          "P",
          "Q",
          "R",
          "S",
          "T",
          "U",
          "V",
          "W",
          "X",
          "Y",
          "Z",
          "a",
          "b",
          "c",
          "d",
          "e",
          "f",
          "g",
          "h",
          "i",
          "j",
          "k",
          "l",
          "m",
          "n",
          "o",
          "p",
          "q",
          "r",
          "s",
          "t",
          "u",
          "v",
          "w",
          "x",
          "y",
          "z",
        ];
        var nums = "";
        for (var i = 0; i < length; i++) {
          var r = parseInt(Math.random() * 61);
          nums += data[r];
        }
        return nums;
      } else {
        return false;
      }
    },
    closeDialog() {
      this.$emit("onPay", false); // 创建订单的状态
      this.$emit("closeDialog");
      //this.doUpdatePayStatus_close();
    },
    CountTimeOver() {
      //倒计时结束触发
      this.$emit("onPay", false); // 创建订单的状态
      this.$emit("closeDialog");
      //this.doUpdatePayStatus_close();
    },
  },
};
</script>

<style lang="scss" scoped>
.font-orange {
  font-size: 26px;
  color: #ffae00;
}
.qrcode-way {
  display: flex;
  align-items: center;
  margin-left: 20px;
  // .mpay-qrcode-container {
  //   position: relative;
  //   width: 160px;
  //   height: 160px;
  //   padding: 5px;
  //   text-align: center;
  //   background: url(../../../assets/images/pay/codeBox.png) no-repeat;
  //   background-size: contain;
  // }
  .qrcode-container {
    width: 100%;
    height: 92%;
    position: relative;
  }
  .mpay-qrcode-tips {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .pay-info-container {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
    .need-to-pay {
      font-size: 18px;
      color: #fa2323;
      font-weight: 700;
      margin: 0 8px 0 2px;
    }
  }
  .qr-tip-zfb {
    font-size: 16px;
    color: #0c9ee0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .qr-tip-wx {
    font-size: 16px;
    color: #3cb73f;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .warning {
    display: block;
    padding: 8px 16px;
    background-color: #fff6f7;
    border-radius: 4px;
    border-left: 5px solid #fe6c6f;
    margin: 20px 0;
    p {
      font-size: 14px;
      color: #5e6d82;
      line-height: 1.5em;
    }
  }
}
</style>
