<template>
  <div class="pay">
    <countdown
      v-if="this.info.isFlag"
      :currentTime="Number(info.currentTime)"
      :startTime="Number(info.startDate)"
      :endTime="Number(info.endDate)"
      :tipText="'expire Time'"
      :tipTextEnd="'Expire Time'"
      :endText="'time out'"
      :dayTxt="' days '"
      :hourTxt="' hours '"
      :minutesTxt="' Mins '"
      :secondsTxt="' Secs '"
    ></countdown>
    <span v-else>no active</span>
    <div class="pay-header">
      <span>
        My Account:
        <em>{{ account }}</em>
      </span>
      <span>
        Balance:
        <em>{{ balance }}B</em>
      </span>
      <a class="fr" @click="linkCardStockPay">
        Go to Coupon Reload
        <i class="iconfont iconyoujiantou"></i>
      </a>
    </div>

    <div class="pay-container">
      <div class="item">
        <p class="title">
          1.Choose your reload amount,<span style="color:red">
            No delete balance after expiry date</span
          >
        </p>
        <ul class="list">
          <li
            v-for="(item, index) in payList"
            :key="index"
            :class="[
              { active: index == currentIndex },
              { big: item.preferential == 0 },
            ]"
            @click="selectPrice(index)"
          >
            <img
              v-if="index == 3 || index == 4 || index == 5 || index == 2"
              class="rec"
              src="@/assets/images/pay/rec.png"
              alt
            />
            <img class="cur" src="@/assets/images/pay/active.png" alt />
            <p class="text1">
              <em class="font-orange">RP {{ item.priceShow }}</em>
            </p>
            <p class="text2" v-if="item.preferential > 0">
              Get {{ item.preferential }} Balance
            </p>
            <p class="text3" v-if="item.days > 0">
              <em class="font-red">{{ item.days }} day active</em>
            </p>
          </li>
          <li class="bg-gray">
            <p class="text3">For other package inquiry,</p>
            <p class="label">please contact our customer service team.</p>
          </li>
        </ul>
      </div>
      <div class="item">
        <p class="title">2.Choose payment method</p>
        <ul class="type-list">
          <li
            v-for="(item, index) in payType"
            :key="index"
            @click="selectPayType(index)"
            :class="index == payTypeIndex ? 'active' : ''"
          >
            <img
              style="width:128px; height:34px;margin:auto;"
              :src="item.img1"
              alt
            />
            <img class="cur" :src="item.img" alt />
          </li>
        </ul>
      </div>
      <div class="item pay-btn-box">
        <el-button type="primary" @click="openPayPop">Buy</el-button>
        <a class="agree" @click="agreen">
          <el-radio v-model="agreeRadio" label="1">Read and agree to </el-radio>
          <em> the terms presented in this website</em>
        </a>
      </div>

      <el-row class="item">
        <el-table :data="tableData">
          <el-table-column type="index" width="50"></el-table-column>
          <el-table-column label="Order ID" prop="batch"></el-table-column>
          <el-table-column
            label="Payment Method"
            :formatter="payFormat"
          ></el-table-column>
          <el-table-column
            label="Reload Amount"
            prop="payAmount"
          ></el-table-column>
          <el-table-column label="Reload Time" prop="payTime"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalSize"
          v-if="totalSize > 10"
        ></el-pagination>
      </el-row>
    </div>
    <Dialog
      :dialogVisible="dialogTableVisible"
      :dialogPaying="isPaying"
      :curPreferential="currentPreferential"
      :currentPrice="currentPrice"
      :currentCouponId="currentCouponId"
      :currentPayType="currentPayType"
      :currentDays="currentDays"
      @closeDialog="closePayPop"
      @onPay="changePay"
    />
    <Serverdialog
      :dVisible="serverDialogVisible"
      @closeDialogs="closeServerDialod"
    />
  </div>
</template>

<script>
import Dialog from "./module/dialog.vue";
import countdown from "@/components/tempcount/countdown.vue";
import { getSessionUser, inviteFriends } from "../../api/auth";
import { getChargeList } from "../../api/order";
import { userCouponList } from "../../api/coupon";
import { message } from "@/utils/resetMessage";
import { getToken } from "@/utils/storage";
import { paypalReadyPay, paypalCapture } from "../../api/pay";
import Serverdialog from "./module/serverdialog";
export default {
  components: {
    Dialog,
    countdown,
    Serverdialog,
  },
  data() {
    return {
      isLoggedIn: false,
      orderNum: "", //订单编号
      userID: "",
      account: "",
      balance: "",
      vipTime: "",
      payList: [],
      codeList: [],
      tableData: [], //列表
      currentPage: 1,
      pageSize: 10,
      totalSize: 0,
      currentIndex: 2, //价格下标
      payTypeIndex: 0, //支付类型下标
      currentPrice: 60000, //价格
      currentPreferential: 10, //余额
      currentDays: 15,
      currentPayType: "Oris", //支付类型
      dialogTableVisible: false, //支付弹窗
      info: {
        currentTime: "",
        startDate: "",
        endDate: "",
        isFlag: false, //是否显示
      },
      payType: [
        {
          icon: "iconzhifubao",
          name: "Oris",
          code: "Oris",
          img: require("@/assets/images/pay/active3.png"),
          img1: require("@/assets/images/pay/oris.png"),
        },
        // {
        //   icon: "iconweixin",
        //   name: "Other",
        //   code: "Other",
        //   img: require("@/assets/images/pay/arrow2.png"),
        //   img1: require("@/assets/images/pay/other.png"),
        // },
        // {
        //   icon: "iconzhifubao",
        //   name: "Bank",
        //   code: "Bank",
        //   img: require("@/assets/images/pay/active3.png"),
        //   img1: require("@/assets/images/pay/PayPal.png"),
        // },
      ],
      agreeRadio: "1",
      isPaying: false, //是否还在支付中
      userCouponList: [], //用户优惠券列表
      currentCouponIndex: "", //优惠券下标
      currentCouponPrice: 0, //优惠券价格
      serverDialogVisible: false, //客服弹窗
      currentCouponId: 0, //优惠券id
      orderId: "", //支付订单ID
    };
  },
  mounted() {
    this.isLoggedIn =
      (this.$store.state.user.token !== null ||
        this.$store.state.user.token !== "") &&
      this.$store.state.user.loginNameType != null
        ? true
        : false;
    this.getUserList();
    this.getPayList();
    this.getTableData();
    this.$store.commit("updateShowAdvert", false);
  },
  methods: {
    payFormat(row) {
      if (row.payWay === 1) {
        return "Card";
      } else {
        return "Bank";
      }
    },
    getUserList() {
      getSessionUser({})
        .then((result) => {
          this.$store.dispatch("setUserInfo", result);
          if (this.$store.state.user.loginNameType) {
            this.account =
              this.$store.state.user.loginNameType == 1
                ? this.$store.state.common.userInfo.mobile
                : this.$store.state.common.userInfo.mail;
            this.balance = result.blance;
            if (this.$store.state.common.userInfo.vipEndTime == null) {
              this.info.isFlag = false;
            } else {
              this.info.currentTime = new Date();
              this.info.startDate = new Date();
              this.info.endDate = new Date(
                this.$store.state.common.userInfo.vipEndTime
              );
              this.info.isFlag = true;
            }
            //获取邀请码
            inviteFriends()
              .then((result) => {
                this.userID = result;
              })
              .catch((error) => {});
            this.orderNum = randomNumber();
          }
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },
    getPayList() {
      this.payList = [
        {
          priceShow: "14.000",
          price: 14000,
          preferential: 2,
          days: 7,
        },
        {
          priceShow: "30.000",
          price: 30000,
          preferential: 5,
          days: 15,
        },
        {
          priceShow: "60.000",
          price: 60000,
          preferential: 10,
          days: 30,
        },
        {
          priceShow: "110.000",
          price: 110000,
          preferential: 20,
          days: 21,
        },
        {
          priceShow: "125.000",
          price: 125000,
          preferential: 25,
          days: 30,
        },
        {
          priceShow: "250.000",
          price: 250000,
          preferential: 50,
          days: 74,
        },
        {
          priceShow: "450.000",
          price: 450000,
          preferential: 100,
          days: 45,
        },
        {
          priceShow: "1.400.000",
          price: 1400000,
          preferential: 350,
          days: 60,
        },
      ];
    },
    selectPrice(index) {
      //设置默认到价格
      this.currentIndex = index;
      this.currentPrice = this.payList[index].price;
      this.currentPreferential = this.payList[index].preferential;
      this.currentDays = this.payList[index].days;
    },
    selectCoupon(index) {
      //选择优惠券
      //设置默认到价格
      this.currentCouponIndex = index;
      this.currentCouponPrice = this.userCouponList[index].typeMoney;
      this.currentCouponId = this.userCouponList[index].user_coupon_id;
      console.log(this.currentCouponId);
    },
    //选择支付方式
    selectPayType(index) {
      this.payTypeIndex = index;
      this.currentPayType = this.payType[index].code;
      if (this.payTypeIndex == 1 || this.payTypeIndex == 2) {
        this.openServerDialog();
      } else {
        //打开新页面
        window.open("https://payment.warungfoto.com/", "_blank");
      }
    },
    //打开客服下载提示
    openServerDialog() {
      this.serverDialogVisible = true;
    },
    closeServerDialod() {
      this.serverDialogVisible = false;
    },
    //前往卡卷充值
    linkCardStockPay() {
      this.$router.push({ path: "/cardStockPay" });
      this.$store.commit("asideMenuId", 3);
    },
    //同意使用声明
    agreen() {
      this.$router.push({ path: "/statement" });
      this.$store.commit("asideMenuId", 7);
    },
    timestampToTime(time) {
      // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var date = new Date(time);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + MM + "-" + d + "-" + " " + h + ":" + m + ":" + s;
    },
    randomNumber() {
      const now = new Date();
      let month = now.getMonth() + 1;
      let day = now.getDate();
      let hour = now.getHours();
      let minutes = now.getMinutes();
      let seconds = now.getSeconds();
      month = this.setTimeDateFmt(month);
      hour = this.setTimeDateFmt(hour);
      minutes = this.setTimeDateFmt(minutes);
      seconds = this.setTimeDateFmt(seconds);
      return (
        now.getFullYear().toString() +
        month.toString() +
        day +
        hour +
        minutes +
        seconds +
        Math.round(Math.random() * 23 + 100).toString()
      );
    },
    //点击充值跳转到支付窗口
    openPayPop() {
      if (this.isLoggedIn) {
        //判断当前支付方式
        window.open("https://payment.warungfoto.com/", "_blank");
        // if (this.payTypeIndex == 1) {
        //   this.openServerDialog();
        // } else {
        //   //随机金额
        //   this.currentPrice =
        //     this.currentPrice + Math.round(Math.random() * 1000);
        //   this.dialogTableVisible = true;
        // }
      } else {
        message.error({
          message: "please Login",
          showClose: true,
          duration: 4000,
          offset: 100,
        });
      }
    },
    closePayPop() {
      this.dialogTableVisible = false;
      this.getUserList();
      this.getPayList();
    },
    changePay(flag) {
      //更换支付状态
      this.isPaying = false;
      this.getTableData();
    },
    getTableData() {
      let dataform = {
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        type: 9,
      };
      //获取充值历史列表
      getChargeList(dataform)
        .then((result) => {
          // 返回到上一个页面
          console.log(JSON.stringify(result));
          this.tableData = result.list;
          this.totalSize = result.count;
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage = 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    getUserCouponDatas() {
      userCouponList()
        .then((result) => {
          if (result != null) {
            this.userCouponList = result;
          }
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
        });
    },
  },
};
</script>
<style lang="scss">
.pay-btn-box {
  .el-button {
    width: 110px;
    line-height: 44px;
    height: 44px;
    padding: 0;
    margin-bottom: 30px;
    text-align: center;
  }
  .agree {
    .el-radio {
      margin-right: 0;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: #8f8f8f;
      cursor: pointer;
    }
  }
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
.font-orange {
  font-size: 21px;
  color: #ffae00;
}
.font-red {
  color: #ff4e00;
}
.font-red1 {
  font-size: 10px;
  color: #ff4e00;
}
.font-black {
  color: #393d49;
}
.pay {
  padding: 25px 40px 40px;
  margin-bottom: 20px;
  background: #fff;
  .pay-header {
    padding-top: 8px;
    padding-bottom: 15px;
    span {
      color: #767676;
      em {
        color: #000;
        font-size: 16px;
      }
      &:nth-child(2) {
        margin-left: 15px;
      }
    }
    .fr {
      float: right;
      font-size: 14px;
      color: #909090;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .pay-container {
    .item {
      padding-top: 30px;
      border-top: 1px solid #e8e8e8;
      overflow: hidden;
      .title {
        padding-bottom: 30px;
        font-size: 14px;
        color: #767676;
      }
      .list {
        margin-bottom: 30px;
        overflow: hidden;
        li {
          margin-right: 10px;
          padding: 16px 16px 0;
          position: relative;
          float: left;
          width: 132px;
          height: 84px;
          border: 1px solid #e8e8e8;
          border-radius: 10px;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
          margin-bottom: 10px;
          &.big {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &.bg-gray {
            display: block;
            height: 70px;
            padding: 30px 16px 0;
            background: #f3f3f3;
            color: #999;
            .label {
              padding-top: 5px;
              font-size: 12px;
              color: #c3c3c3;
            }
          }
          &.active {
            border: 1px solid #ff7a03;
            box-shadow: 1px 1px 10px #ffdcbd;
            .cur,
            .rec {
              display: block !important;
            }
          }
          .cur {
            position: absolute;
            right: 0;
            bottom: 0;
            display: none;
          }
          .rec {
            position: absolute;
            left: 0;
            top: 0;
            display: none;
          }
          .text1 {
            padding-bottom: 6px;
            color: #343851;
          }
          .text2 {
            padding-top: 6px;
            margin: 0 auto;
            width: 80%;
            border-top: 1px solid #e8e8e8;
            color: #8e909b;
            font-size: 14px;
          }
          .text3 {
            margin: 0 auto;
            width: 80%;
            color: #8e909b;
            font-size: 14px;
          }
        }
      }
      .couponlist {
        margin-bottom: 30px;
        overflow: hidden;
        li {
          margin-right: 10px;
          padding: 16px 16px 0;
          position: relative;
          float: left;
          width: 132px;
          height: 84px;
          border: 1px solid #ffdcbd;
          border-radius: 10px;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
          margin-bottom: 10px;
          &.big {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &.bg-gray {
            border: 1px solid #9f9f9f;
            display: block;
            height: 70px;
            padding: 30px 16px 0;
            background: #f3f3f3;
            color: #999;
            .label {
              padding-top: 5px;
              font-size: 12px;
              color: #c3c3c3;
            }
          }
          &.active {
            border: 1px solid #ff7a03;
            box-shadow: 1px 1px 10px #ffdcbd;
            .cur,
            .rec {
              display: block !important;
            }
          }
          &.disabled {
            border: 1px solid #9f9f9f;
            pointer-events: none;
            opacity: 0.4;
            background: #eaeaea;
          }
          .cur {
            position: absolute;
            right: 0;
            bottom: 0;
            display: none;
          }
          .rec {
            position: absolute;
            left: 0;
            top: 0;
            display: none;
          }
          .text1 {
            padding-bottom: 6px;
            color: #343851;
          }
          .text2 {
            padding-top: 6px;
            margin: 0 auto;
            margin-bottom: 10px;
            width: 80%;
            border-top: 1px solid #e8e8e8;
            color: #8e909b;
            font-size: 14px;
          }
          .text3 {
            padding-bottom: 6px;
            color: #343851;
          }
          .text4 {
            padding-top: 6px;
            margin: 0 auto;
            margin-bottom: 2px;
            width: 80%;
            border-top: 1px solid #e8e8e8;
            color: #8e909b;
            font-size: 14px;
          }
          .text5 {
            color: #ff4e00;
            font-size: 10px;
          }
        }
      }
      .type-list {
        li {
          margin-right: 30px;
          position: relative;
          float: left;
          display: flex;
          width: 178px;
          height: 80px;
          line-height: 80px;
          border: 1px solid #e8e8e8;
          border-radius: 10px;
          color: #7d7d7d;
          font-size: 16px;
          flex-direction: row;
          justify-content: center;
          align-content: center;
          cursor: pointer;
          &:nth-child(2).active,
          &:nth-child(1).active {
            color: #666;
            .cur {
              display: block !important;
            }
          }
          &:nth-child(2).active {
            border: 1px solid #3cb73f;
          }
          &:nth-child(1).active {
            //border: 1px solid #3cb73f;
            border: 1px solid #0c9ee0;
          }
          &:nth-child(2) {
            color: #3cb73f;
            .iconfont {
              margin-right: 6px;
              color: #3cb73f;
            }
          }
          &:nth-child(1) {
            .iconfont {
              margin-right: 15px;
              //color: #3cb73f;
              color: #0c9ee0;
            }
          }
          .iconfont {
            font-size: 38px;
          }
          .cur {
            position: absolute;
            right: 0;
            bottom: 0;
            display: none;
          }
        }
      }
    }
    .text-item {
      margin-left: 20px;
      display: inline-block;
      .text1 {
        margin-top: 72px;
        font-size: 16px;
        .font-orange {
          font-size: 24px;
        }
      }
      .text2 {
        margin-top: 10px;
        font-size: 16px;
        color: #999;
      }
      .text3 {
        margin-top: 10px;
        font-size: 16px;
        color: #999;
      }
    }
    .pay-btn-box {
      margin-top: 30px;
      .agree {
        margin-left: 40px;
        cursor: pointer;
        .el-radio {
          margin-right: 0;
        }
        .el-radio__input.is-checked + .el-radio__label {
          color: #8f8f8f;
          cursor: pointer;
        }
        em {
          color: #2a2a2a;
          text-decoration: underline;
        }
      }
    }
  }
}
</style>
