<template>
  <el-dialog title="Tips" :visible.sync="visible" @close="closeDialog" class="table" width="620px">
    <div class="pop-center-list">
      <div class="code">
        <el-row class="container2">
        <h2 class="title">About Top up</h2>
        <p>
          <em>Pembayaran manual dan infokan ke Whatsapp</em>
        </p>
        <p>
          <em>Untuk Pembayaran bisa ke a/n afriansyah</em>
        </p>
        <p>  
          BCA: 5271307358</br>
          Mandiri: 1210007530201</br>
          Gopay: 08989230477 / 081510276266</br>
          Ovo: 08989230477 / 081510276266</br>
          Dana: 08989230477</br>
          Jenius: 90011353025</br>
          CIMB: 703497439800</br>
          BNI: 0908718205</br>
          Seabank: 901988089156</br>
        </p>
        <p>
          Virtual account a/n afriansyah </br>
          BRI: 1270100000896600</br>
          BCA: 1375300000896600</br>
        </p>
      </el-row>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: ["dVisible"],
  watch: {
    dVisible: {
      handler(val) {
        this.visible = val;
      },
      deep: true
    }
  },
  data() {
    return {
      visible: false,
      wechatImg: ''
    };
  },
  methods: {
    linkOverseas(row) {
      window.open(row.enUrl);
    },
    linkDomestic(row) {
      window.open(row.zhUrl);
    },
    closeDialog() {
      this.$emit("closeDialogs");
    },
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

  .pop-center-list {
    text-align: center;
    width: 580px;
    z-index: 22;
    .code {
      padding: 10px 0 10px;
      text-align: center;
      img {
        height: 180px;
        width: 180px;
      }
      p {
        margin-top: 6px;
        font-size: 18px;
        color: #666;
      }

      .container2 {
        margin-bottom: 10px;
        background: #fff;
        border: 1px solid #feaf06;
        padding: 5px;
        .title {
          margin-bottom: 16px;
          line-height: 50px;
          font-size: 16px;
          color: #1f1f1f;
          border-bottom: 1px solid #e8e8e8;
        }
        p {
          margin-bottom: 15px;
          line-height: 20px;
          color: #898989;
        }
      }
    }
  }

</style>
